body {
  margin: 0;
  font-family: 'Helvetica', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
  margin-left: 1rem;
}

h1 {
  font-size: 32px !important;
  line-height: 48px;
}

h2 {
  font-size: 24px !important;
  line-height: 36px;
}

h3 {
  font-size: 20px !important;
  line-height: 30px;
}

@font-face {
  font-family: assistant;
  src: url(../public/Assistant-Regular.ttf);
}

.assistant {
  font-family: assistant;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
